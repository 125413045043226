import React from "react"
import { graphql } from "gatsby"

import Layout from "../components/templates/layout"
import SEO from "../components/seo"
import MainPage from "../components/templates/MainPage"

export const query = graphql`
  query MyQuery2 {
    allMysqlManufacturers(sort: {fields: name}) {
      nodes {
        name
        color_cross_refs {
          adjusted_rgb
          color_name
          idh
          quad_url
          idhmax
          quad_max_url
          osi
          available
        }
        special_color_cross_refs {
          adjusted_rgb
          color_name
          idh
          idhmax
          osi
          available
        }
      }
    }
  }
`


const IndexPage = ({ data }) => {

  let manufacturers = data.allMysqlManufacturers.nodes;
  let dedupColors = [];
  let colorNameList = [];

  // remove duplicate colors from each manufacturer, then sort by color_name
  manufacturers.forEach(manufacturer => {
    dedupColors = [];
    colorNameList = [];
    manufacturer.color_cross_refs.forEach(color => {
      if(!colorNameList.includes(color.color_name)) {
        color.special = false;
        colorNameList.push(color.color_name);
        dedupColors.push(color)
      }
      else {
        dedupColors.filter(old_color => {
          if(old_color.color_name === color.color_name) {
            if(color.idh) {
              old_color.idh += old_color.idh ? ', ' + color.idh : color.idh
              old_color.quad_url  += old_color.quad_url ? ', ' + color.quad_url : color.quad_url
              old_color.quad_description += old_color.quad_description ? ', ' + color.quad_description : color.quad_description
              old_color.quad_fr_url  += old_color.quad_fr_url ? ', ' + color.quad_fr_url : color.quad_fr_url
              old_color.quad_fr_description += old_color.quad_fr_description ? ', ' + color.quad_fr_description : color.quad_fr_description
              old_color.multivalIdh = true;
            }
            if(color.idhmax) {
              old_color.idhmax += old_color.idhmax ? ', ' + color.idhmax : color.idhmax
              old_color.quad_max_url += old_color.quad_max_url ? ', ' + color.quad_max_url : color.quad_max_url
              old_color.quad_max_description += old_color.quad_max_description ? ', ' + color.quad_max_description : color.quad_max_description
              old_color.quad_max_fr_url += old_color.quad_max_fr_url ? ', ' + color.quad_max_fr_url : color.quad_max_fr_url
              old_color.quad_max_fr_description += old_color.quad_max_fr_description ? ', ' + color.quad_max_fr_description : color.quad_max_fr_description
              old_color.multivalIdhMax = true;
            }
          }
          return old_color;
        })
      }
    })
    manufacturer.special_color_cross_refs.forEach(color => {
      if(!colorNameList.includes(color.color_name)) {
        color.special = true;
        colorNameList.push(color.color_name);
        dedupColors.push(color)
      }
    })
    dedupColors = dedupColors.sort((a, b) => {
      if(a.color_name < b.color_name) {return -1;}
      if(a.color_name > b.color_name) {return 1;}
      return 0;
    })
    manufacturer.color_cross_refs = dedupColors;
  });

  return (
  <Layout>
    <SEO title="Colour Match Tool" />
        <MainPage manufacturers={data.allMysqlManufacturers.nodes} />
  </Layout>
  )
}

export default IndexPage
